/* Common CSS */

#calculator {
    font-family: Quicksand;
    cursor: auto;
    background: none;
    border: none;
    min-height: 550px;
}

#calculator * {
    outline: none !important;
}

#calculator *:focus {
    outline: 0px !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
}

#calculator .text-orange {
    color: #F68B1F;
}

#calculator .btn-orange {
    background-color: #F68B1F;
    color: #fff;
    border: none;
}

#calculator .btn-orange:hover {
    background-color: #dd7d1b;
}

#calculator .vertical-separator {
    width: 1px;
    height: 90%;
    background-color: #212529;
    margin: 0 auto;
}

.orange-range::-webkit-slider-thumb {
    background: #F68B1F !important;
  }
  
.orange::-moz-range-thumb {
    background: #F68B1F !important;
}

.orange-range::-ms-thumb {
    background: #F68B1F !important;
}

/* IconCard (Step 1, Step 2, Step 3) CSS */

#calculator .icon-card {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.175);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
}

#calculator .icon-card .subtitle {
    font-size: 18px;
}

#calculator .icon-card .icon {
    font-size: 40px;
}

/* Quote CSS */

#calculator .badge-success {
    background-color: #468847;
    color: white;
}

#calculator .system-features {
    list-style-type: none;
    padding: 0px;
}

#calculator .system-features li i {
    margin-right: 8px;
}

/* SpecsCard (Step 4) CSS */

#calculator .specs-card {
    -webkit-box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 17px -8px rgba(0,0,0,0.75);
    cursor:default;
}

#calculator .specs-card .card-title {
    font-weight: bold;
}

/* Mobile view CSS */

@media only screen and (max-width: 640px) {
    #calculator .vertical-separator {
        display: none;
    }
}