@use "variables";

.gray{
  background-color: #e9ecef!important;
}

svg{
  width: 70px;
}
.orange{
  color: #F68B1F;
}

.font{
  font-family: 'Quicksand', sans-serif;
}

.orange-border{
  border-color: #F68B1F;
}

.benefits-link{
  color: #F68B1F;
}

/* EXPLANATION */

.explanation-container {
  padding: 70px 25px !important;
  border-radius: 1em;
  background-color: #fff;

  @media all and (max-width: 575px) {
    padding: 70px 0px !important;
  }

  & .explanation-section {
    margin-bottom: 25px;
    border-radius: 1em;
    padding: 40px 20px;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-of-type(2n) {
      background-color: #f5f5f5 !important;
    }

    & h2 {
      text-align: center;
      font-size: 35px;
    }

    & img {
      border-radius: 1em;
    }
  
    & figcaption {
      margin-top: 10px;
      margin-left: 5px;
    }
  }
}

// TLDR

ul.tldr, ol.tldr {
  & li {
    list-style-type: none;


  &::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: variables.$primary; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }
}

// WhyMantula.js -> CORE VALUES

.values-container {
  border-radius: 3em;
  padding: 40px 25px !important;

  h2 {
    margin-bottom: 20px !important;
  }

  .values-icon {
    margin-top: 30px;
    margin-right: 15px;

    img {
      width: 5rem;
    }
  }

  .values-section {
    h3 {
      color: variables.$primary;
    }
  }
}

// Benefits.js -> BENEFIT CARDS

.benefits-card {
  border-radius: 1em;
  overflow: hidden;
}

// PPA

.ppa-benefit-title {
  @media all and (max-width: 991px) {
    color: variables.$primary;
  }
}