.font{
  font-family: 'Quicksand', sans-serif;
}

.text-indent {
  padding-left: 20px;
}

.second-line-indented {
  padding-left: 33px;
}

.btn {
  color: white;
}

.btn:focus {
  color: white;
}

.link {
  text-decoration: none;
}

.link:visited {
  color: #F68B1F;
}

h5 {
  font-size: 20.5px;
}

i {
  margin-right: 10px;
  color: #F68B1F;
}

.hero-img-container {
  position: relative;
  width: 100%;
  height: max-content;
  min-height: 550px;
  background-size: cover;
  background-position: center;
}

.calc-intro {
  justify-content: center;
  padding-left: 15%;
  padding-right: 15%;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.pic-container {
  width: 350px;
  margin-top: 1rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.pic {
  margin-bottom: 1rem;
}

.indent {
  text-indent: 100px;
}

nav {
  background-color:#343A40;
  text-align: center;
}

.hero-img-container {
  background-position: center top; 
  height: 80vh;
}

#subtitle {
  margin-left: 1rem; 
  color: #fff; 
  opacity: 80%;
}

.content-container {
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -1rem;
}

#main-heading {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem; 
  text-align: center;
}

.system-container { 
  color: black;
  margin-top: 1.5%;
  margin-right: 16%;
  margin-left: 16%;
}

.system-title {
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.flex {
  display: flex;
  flex-direction: row;
}

.bullet-heading {
  font-size: 28px;
  margin-bottom: 0.8rem;
  margin-top: 1.5rem;
}

.links {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.review-button {
  margin-right: 1rem;
}

.learn-more {
  margin-top: 3rem;
}

.description {
  text-align: left;
  margin-left: 5rem;
  width: 700px; 
  font-size: 20px;
}

.calculator-container {
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
}

.calculator-container .calculator-enquiry {
  text-align: left;
}

#rebate {
  margin-bottom: 1.5rem;
}

.rebate {
  text-align: center;
}

.step {
  padding-bottom: 1.5rem;
}

.form-container {
  width: 60%;
  padding-top: 3rem;
  padding-left: 16%;
  padding-right: 16%;
}

.hr {
  padding-left: 9%;
  padding-right: 9%
}