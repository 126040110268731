// START HERO IMAGE CSS ----------------------

@use "variables";

.hero {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  // needed to prevent janky scrolling in Safari
  backface-visibility: hidden;
  //100% height of the viewport
  height: calc(90vh - 106px);
  overflow: hidden;
  position: relative;
  width: 100%;

  @media all and (max-width: 800px) {
    .scroll {
      display: none;
    }
  }

  @media screen and (min-width: 640px) {
    background-position: 50% 0;
  }

  // Add a slight overlay on our image
  &:after {
    background: linear-gradient(to left, #FFFFFF00, variables.$secondary);
    bottom: 0;
    content: '';
    left: -2000%;
    position: absolute;
    right: -2000%;
    top: 0;
    z-index: 1;
  }
}


.hero-container {
  box-sizing: border-box;
  height: auto;
  padding: 60px 0;
  position: relative;
  z-index: 2;

  @media screen and (min-width:640px) {
    height: 100%;
  }
}

a{
  text-decoration: none;
  color:#F68B1F;
}
a:hover{
  color:#343A40;
}

// Start Animation Tweaks
// Need to refactory and Sassify–just proof-of-concept and testing right now
// Basically tweaking transform, translates inside keyframes and adding delays

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-12px, 0, 0);
    //transform: translate3d(-100%, 0, 0);
    transform: translate3d(-12px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.animate {
  animation-duration: 0.75s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.2, 0.3, 0.25, 0.9);
}

.delay {
  animation-delay: 1s;
}

.delay-400 {
  animation-delay: 0.4s;
}

.delay-500 {
  animation-delay: 0.5s;
}

.delay-600 {
  animation-delay: 0.6s;
}

.delay-700 {
  animation-delay: 0.7s;
}

.delay-800 {
  animation-delay: 0.8s;
}

// END HERO IMAGE CSS ----------------------

// START ABRIDGED CSS IMPORTED FROM OLD INDEX.CSS ---------------
.card {
  text-align: center;
  cursor: pointer;
}

img.listPic {
  width: 45px;
  margin-right: 1.5rem;
  vertical-align: bottom;

  @media all and (max-width: 450px) {
    display: none;
  }
}

li.media {
  margin-bottom: 2em;
}

div.row {
  padding-top: 2em;
  padding-bottom: 2em;
}

div.outer.bgPic {
  opacity: 1;
  height: auto;
  background-position: center bottom;
}

@media (max-width: 767px) {
  .bgPic {
    background-position: 75% 100% !important;
  }

  .my-4 {
    padding-top: 0%;
  }
}

@media (max-width: 991px) {
  .my-4 {
    padding-top: 0%;
  }
}



@media (max-width: 576px) {
  .material-icons.md-48 {
    font-size: 36px;
  }

  h2 {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 2rem;
  }


}

// END INDEX.CSS

// START CSS FOR FLIPPING CARDS
.card-flip {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-template-areas: "frontAndBack";
  transform-style: preserve-3d;
  transition: all 0.7s ease;
}

.card-flip div {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.front {
  grid-area: frontAndBack;
}

.back {
  grid-area: frontAndBack;
  transform: rotateY(-180deg);
}

.card-container:hover .card-flip {
  transform: rotateY(180deg);
}

// END CSS FOR FLIPPING CARDS


// START CSS COPIED FROM INSPECT ELEMENT TO GET THE HOME PAGE TO LOOK THE SAME
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

// END CSS COPIED

// START MISC CSS

.img-width-10-perc {
  width: 10%;
}

.thin-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.thin-orange-border {
  border: #F68B1F solid 1px;
  padding: 3px;
}

.scroll {
  position: absolute;
  bottom: 2rem;
  // margin: auto;
  display: block;
  width: 25%;
  left: 37.5%;
  text-align: center;
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;

  text-decoration: none;

  :hover {
    color: variables.$primary;
  }
}

@-webkit-keyframes bounce {

  0%,
  100% {
    -webkit-transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.mediaTitleLink {
  color: variables.$secondary;
  text-decoration: none;
}

.mediaTitleLink:hover {
  color: variables.$primary;
}

.mediaTitleImg {
  transition: 100ms linear;
}

// END MISC CSS

// SOLUTIONS CSS

.solutions-row {
  max-width: 1100px;
  margin: 80px auto 0px;

  @media all and (max-width: 768px) {
    margin: 15px auto;
  }

  .solutions-description {
    font-size: 18px;
    font-weight: 400;
    margin: 30px 0;

    @media all and (max-width: 500px) {
      br {
        display: none;
      }
    }
  }

  .solutions-image {
    display: block;
    width: calc(100% - 60px);
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0.5em;

    @media all and (max-width: 767px) {
      width: 100% !important;
    }
  }

  & .btn a {
    color: #fff;
  }

  & .solutions-calculator-container {
    & a {
      color: variables.$primary;
      font-weight: bold;

      & i {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        color: variables.$primary;

        & i {
          margin-left: 5px;
        }
      }
    }

    & p {
      font-size: 16px;
    }
  }
}

// ADVANTAGES

.advantages-overlay {
  background-color: rgba(52, 58, 64, 0.6);

  & h2 {
    padding-left: 50px;
    padding-top: 28%;

    @media all and (max-width: 991px) {
      padding-top: 0;
    }

    @media all and (max-width: 960px) {
      padding-left: 0;
    }

    & span {
      text-decoration: underline;
      text-decoration-color: variables.$primary;
    }
  }
}

// WHY SOLAR

.solar-card {
  height: 175px !important;
  box-sizing: border-box;
  padding: 0 15px;

  @media all and (max-width: 767px) {
    height: auto !important;
    padding: 10px 15px;

    & h3, svg, span {
      font-size: 22px;
      margin: 0;
    }
  }

  & .solar-icon {
    display: block;
    font-size: 40px;
    height: 40px;
    width: 40px;
    margin: 0 auto;
    color: variables.$primary;

    @media all and (max-width: 767px) {
      display: inline-block;
      font-size: 30px;
      height: 30px;
      width: 30px;
      margin-bottom: 0;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}

// CO2 REIMBURSMENT

.co2-avoidance-title {
  margin-bottom: 30px !important;
}

.co2-reimbursment {
  font-weight: bold;
  font-size: 22px !important;
  color: variables.$primary;
  margin: 0;
  // margin: 30px 0;
  padding: 10px;
  // border: #343A40;
}

.co2-reimbursment-outer{
  padding:2px;
  margin-bottom: 10px;
}

.co2-image {
  width: 85%;
  vertical-align: middle;
}

// PPA

.ppa-title {
  margin-bottom: 30px !important;
}

.ppa-image {
  width: 80%;
  vertical-align: middle;
}
// .wc-text {
//   position: relative;
//   bottom: 3*42px;
// }

.watercolor-img{
  width: 50%;
}

// .watercolor-container{
//   position:absolute;

// }