@use "variables";

$theme-colors: (
  "primary": variables.$primary,
  "secondary": variables.$secondary,
  "success": variables.$success,
  "info": variables.$info,
  "warning": variables.$warning,
  "danger": variables.$danger,
  "light": variables.$light,
  "dark": variables.$dark,
  "gray": variables.$gray
);

$enable-responsive-font-sizes: true;
$enable-negative-margins: true;

$input-focus-border-color: variables.$primary;
$input-focus-box-shadow: none;

body {
  position: relative;
  font-family: 'Quicksand', sans-serif;
  font-weight: 300;
}

.secondary-text-color {
  color: variables.$secondary_text;
}

.nav-styles{
  transition-timing-function: ease-in;
  transition: 0.5s;
}

#nav-dropdown {
  color: #fff !important;
}

@media all and (max-width: 991px) {
  body {
    margin-top: 81px !important;
  }

  .navbar {
    position: fixed !important;
    width: 100% !important;
    z-index: 1000 !important;
    top: 0 !important;
  }
}

.navbar .navbar-brand img {
  height: 70px !important;

  @media all and (max-width: 991px) {
    height: 55px !important;
  }
}

.navbar-nav {
  a {
    display: inline-block;
    width: auto;
    height: auto;
    border-radius: 3em;
    padding: 5px 15px !important;
    align-self: center;
  
    &:hover {
      background-color: #2c3136;
    }
  }

  span {
    padding: 8px 0;
  }

  div {
    align-self: center;
  }
}

.dropdown-menu {
  background-color: variables.$secondary !important;
  color: #fff !important;
  padding: 8px 16px !important;
  margin-top: 8px !important;

  a {
    margin: 8px;

    @media all and (max-width: 991px) {
      display: block;
      margin-top: 15px;
      text-align: center;
    }
  }
}

.dropdown-item:hover{
  color: white !important;
}
.dropdown-item {
  color: white !important;
}

#quote-button {
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  margin-left: 15px;

  @media all and (max-width: 991px) {
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 10px 45px;
  }
}

#nav-dropdown::after {
  margin-left: 15px;
  vertical-align: 3px;
}

.navbar-toggler {
  color: #fff !important;
  border-color: #fff !important;

  &:focus {
    box-shadow: none !important;
  }

  i {
    color: #fff;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27><path stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/></svg>") !important;
}

.carousel-item img {  
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  height: 50vh;
}

@media (max-height:750px) {
  .carousel-item img {  
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    height:75vh;
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.color-background {
  background: linear-gradient(to top, #FFFFFF00, variables.$secondary);
}

.hero-img-container {
  position: relative;
  width: 100%;
  height: max-content;
  min-height: 450px;
  background-size: cover;
  background-position: center;
  font-weight: 400;

  &.small {
    height: auto;
    min-height: auto;
    background-position: center center;

    .hero-title {
      display: inline-block;
    }

    .overlay {
      position: relative !important;
    }

    .hero-container {
      padding: 90px 15px;
    }
  }

  @media all and (max-width: 800px) {
    height: auto !important;

    .hero-title {
      font-size: 60px;
    }
  }

  @media all and (max-width: 570px) {
    .hero-title {
      font-size: 52px;

      br {
        display: none;
      }
    }

    .container {
      padding: 30px;
    }
  }

  @media all and (max-width: 450px) {
    .hero-title {
      font-size: 46px;
    }
  }
}

.hero-image {
  display: block;
  width: 100%;
  height: 100%;
}

.hero-title {
  font-size: 72px;
  color: variables.$light;
  font-weight: 400 !important;
}

.hero-subtitle {
  font-size: 20px;
  color: variables.$light;
  font-weight: 400;
}

.hero-button {
  font-size: 18px;
  color: variables.$light;
  background-color: variables.$primary;
  font-weight: 500 !important;
  border-radius: 3em;
  padding: 12px 26px;
  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: variables.$primary_darker;
  }
}

.overlay {
  position: absolute;
  top: 0%;
  left: 0%;
}

.overlay-text {
  justify-content: center;

}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  background-size: cover;
  object-fit: scale-down;
  background-size: cover;
  // background-position: center;
  // overflow: hidden;
}

.nopadding{
  padding: 0;
}

.parallax {
  /* The image used */
  background-image:    
  linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(4, 3, 4, 0.73)),
  url('../img/whySolar/scrolledHeroImage.jpg');

  /* Full height */
  height: 100%; 
  width: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.contactInfo {
  color: white;
  background: linear-gradient(to top, variables.$secondary, variables.$primary, variables.$secondary);
  background-color: variables.$secondary;
}

.bi-facebook ,.bi-google ,.bi-instagram ,.bi-linkedin{
  color: white;
}

html * {
  font-family: Quicksand;
}

.carousel-caption {
  color: variables.$primary;
}

.translucent-bg {
  background-color: rgba(255, 255, 255, 0.75) !important;
}

.footer {
  background-color: variables.$secondary;
  color: white;
}

.headerBg {
  background-color: variables.$secondary;
}

.dropdownBg {
  background-color: variables.$secondary;
}
svg {
  pointer-events: none;
}

path{
  pointer-events: auto;
}

.btn {
  border-radius: 3em !important;
  outline: none !important;
  border: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.btn-primary {
  &:hover {
    color: #fff !important;
    background-color: variables.$primary_darker !important;
  }
}

.btn-primary-inverse {
  background-color: #fff !important;
  color: variables.$primary !important;

  &:hover {
    background-color: #eee !important;
    color: variables.$primary !important;
  }
}

.image-vertical-align-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

// SECTION CSS

.section-title-container {
  padding: 45px 0;

  & h2 {
    margin: 0 0 10px;
    font-size: 42px;
  }

  & h3 {
    font-size: 20px;
    font-weight: 400;
  }

  & p {
    font-size: 18px;
  }
}

// MESSAGE US CSS

.message-us-row {
  width: 70%;
  margin: 0 auto !important;

  @media all and (max-width: 1100px) {
    width: 85%;
  }

  @media all and (max-width: 991px) {
    width: 100%;
  }
}

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";