@use "variables";

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: variables.$primary;
}

.accordion-button:hover {
  color: #fff;
  background-color: variables.$primary;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(0);
}

.accordion-button:focus {
  box-shadow: inherit;
}

.clickable-path {
  cursor: pointer !important;
}

.clickable-path:hover {
  fill: variables.$primary !important;
}